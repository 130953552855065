import React, { Component } from 'react';
import MP4Boxes from './MP4Boxes.js';

class MP4Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(...args) {
    this.setState(state => ({ collapsed: !state.collapsed }));
  }

  render() {
    let type = this.props.box.hdr.type;
    let size = this.props.box.hdr.size;
    let hasChildren;
    if (this.props.box[type] && this.props.box[type].children) {
      hasChildren = this.props.box[type].children;
    }
    let collapsed = this.state.collapsed;
    let keys;
    if (this.props.box[type]) {
      keys = Object.keys(this.props.box[type]).filter(k => k !== 'children' && k !== 'samples');
    }
    return (
      <div className="mp4box">
        { (keys && keys.length > 0) || hasChildren ?
          <div className={collapsed ? 'mp4box-arrow mp4box-arrow-collapsed' : 'mp4box-arrow'} onClick={this.handleClick}></div>
        : <div className="mp4box-noarrow"></div>
        }
        {type} [{size}]
        <div className={collapsed ? 'mp4box-children mp4box-children-collapsed': 'mp4box-children'}>
          {hasChildren && !collapsed ? <MP4Boxes boxes={hasChildren} /> : null }
          {!collapsed && keys ?
            <table>
              <tbody>
                {keys.map((k, idx) => {
                  return (
                  <tr key={idx}>
                    <td>{k}</td>
                    <td>=</td>
                    <td>{this.props.box[type][k].toString()}</td>
                  </tr>
                  );
                })}
              </tbody>
            </table>
          : null}
        </div>
      </div>
    )
  }
}

export default MP4Box;