import React, { Component } from 'react';
import Files from 'react-files';
import ProgramMapTables from './ProgramMapTables.js';
import ResponseDetails from './ResponseDetails.js';
import SegmentPlot from './SegmentPlot.js';
import AACDataPlot from './AACDataPlot.js';
import AVCDataPlot from './AVCDataPlot.js';
import MP4Boxes from './MP4Boxes.js';
import MP4Info from './MP4Info.js';
import { Readable } from 'stream';

const request = require('request');
const FileReaderStream = require('filereader-stream');
const HlsTs = require('hls-ts');
const fMP4 = require('fmp4.js');

class SegmentInspector extends Component {
  constructor(props) {
    super(props);

    this.state = { url: this.props.default || '', parsing: false, error: null, hideinput: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleB64Change = this.handleB64Change.bind(this);
    this.handleDropboxChange = this.handleDropboxChange.bind(this);
    this.handleDropboxError = this.handleDropboxError.bind(this);
  }

  handleChange(event) {
    this.setState({url: event.target.value });
  }

  handleB64Change(event) {
    this.setState({ parsing: true, boxes: null, programs: null, avcPayload: null});
    let buff = new Buffer(event.target.value, 'base64');
    let stream = new Readable();
    stream.pipe(fMP4.parse({ debug: false }))
    .on("error", err => {
      console.error(err);
      this.setState({ error: err.message, parsing: false });
    })
    .on("finish", () => {
      let boxes = fMP4.boxes.map(b => b.parse());
      this.setState({
        boxes,
        parsing: false,
        hideinput: true,
      });
    });
    stream.push(buff);
    stream.push(null);
  }


  async handleSubmit(event) {
    event.preventDefault();

    this.setState({ parsing: true, boxes: null, programs: null, avcPayload: null });

    const { stream, headers } = await new Promise((resolve) => {
      let stream = request({ url: this.state.url, time: true })
      .on("response", response => {
        if (response.statusCode !== 200) {
          this.setState({ error: `Failed to download chunk: ${response.statusMessage}` });
          resolve({ stream });
        } else {
          this.setState({ 
            headers: response.headers, 
            timings: response.timingPhases,
          });
          resolve({ stream, headers: response.headers });
        }
      });
    });

    if (this.state.url.endsWith('.ts')) {
      stream.pipe(HlsTs.parse({ debug: false }))
      .on("error", err => {
        console.error(err);
        this.setState({ error: err.message, parsing: false });
      })
      .on("finish", () => {
        this.setState({ 
          programs: HlsTs.programs, 
          avcPayload: HlsTs.getDataStreamByProgramType('avc'),
          aacPayload: HlsTs.getDataStreamByProgramType('aac'),
          error: null
        });
      })
    } else if (headers["content-type"] === "video/mp4" || this.state.url.endsWith('.mp4') || this.state.url.endsWith('.dash')) {
      stream.pipe(fMP4.parse({ debug: false }))
      .on("error", err => {
        console.error(err);
        this.setState({ error: err.message, parsing: false });
      })
      .on("finish", () => {
        let boxes = fMP4.boxes.map(b => b.parse());
        this.setState({
          boxes,
          parsing: false,
        });
      });
    }
  }

  handleDropboxChange(files) {
    if (files.length > 0) {
      let file = files[0];
      this.setState({ parsing: true, boxes: null, programs: null, avcPayload: null });

      let stream = FileReaderStream(file, { chunkSize: 200 * 1024 * 1024 });

      if (file.extension.toLowerCase() === 'mp4' || file.extension.toLowerCase() === 'dash' || file.extension.toLowerCase() === 'mov' || file.type === "video/mp4") {
        stream.pipe(fMP4.parse({ debug: false }))
        .on("error", err => {
          console.error(err);
          this.setState({ error: err.message, parsing: false });
        })
        .on("finish", () => {
          let boxes = fMP4.boxes.map(b => b.parse());
          this.setState({
            boxes,
            parsing: false,
            hideinput: true,
          });
        });      
      } else if (file.extension === 'ts') {
        stream.pipe(HlsTs.parse({ debug: false }))
        .on("error", err => {
          console.error(err);
          this.setState({ error: err.message, parsing: false });
        })
        .on("finish", () => {
          this.setState({ 
            programs: HlsTs.programs, 
            avcPayload: HlsTs.getDataStreamByProgramType('avc'),
            aacPayload: HlsTs.getDataStreamByProgramType('aac'),
            error: null,
            hideinput: true
          });
        });        
      } else {
        console.error('File with extension ' + file.extension + ' not supported');
        this.setState({ error: 'File not supported', parsing: false, hideinput: true });
      }
    }
  }

  handleDropboxError(err, file) {
    this.setState({ error: err.message, parsing: false });
  }

  render() {
    return (      
      <div className="segment-inspector">
        { this.state.hideinput === false ?
          <div>
            <form onSubmit={this.handleSubmit}>
              <label>
                URL to Video Segment:<br/>
                <input type="text" size="40" value={this.state.url} onChange={this.handleChange} />
              </label>
              <input type="submit" value="Parse"/>
            </form>
            <p className="small"><b>or upload a file from your computer:</b></p>
            <Files className="files-dropzone" 
                onChange={this.handleDropboxChange} 
                onError={this.handleDropboxError} 
                maxFiles={1} clickable>
              Drop file here or click to upload
            </Files>
            <p className="small"><b>or paste base64 encoded bitstream here:</b></p>
            <form>
              <textarea rows="6" cols="80" value={this.state.base64} onChange={this.handleB64Change} />
            </form>
          </div>
          : null }
        {this.state.headers ?
        <div>
          <ResponseDetails headers={this.state.headers} timings={this.state.timings}/>
        </div>
        : null
        }
        {this.state.boxes ?
          <div>
            <MP4Info boxes={this.state.boxes} />
            <MP4Boxes boxes={this.state.boxes} />
          </div>
        : null }
        {this.state.programs ?
          <div>
            <ProgramMapTables programs={this.state.programs} />
            <SegmentPlot avcPayload={this.state.avcPayload} aacPayload={this.state.aacPayload}/>
            { this.state.avcPayload ? <AVCDataPlot payload={this.state.avcPayload}/> : null }
            { this.state.aacPayload ? <AACDataPlot payload={this.state.aacPayload}/> : null }
          </div> 
          : this.state.parsing ? <p>Parsing...</p> : null 
        }
        {this.state.error ?
          <div>
            <p className="red">{this.state.error}</p>
          </div> 
        : null }
      </div>
    )
  }
}

export default SegmentInspector;