import React, { Component } from 'react';

class MP4Info extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tracks: [],
      chunks: []
    }
  }

  componentDidMount() {
    let boxes = this.props.boxes;
    let tracks = [];
    let chunks = [];

    boxes.forEach(box => {
      if (box.hdr.type === 'moov') {
        let trakBoxes = box.moov.children.filter(b => b.hdr.type === 'trak');
        trakBoxes.forEach(trakBox => {
          let tkhdBox = trakBox.trak.children.find(b => b.hdr.type === 'tkhd');
          if (tkhdBox) {
            let track = {
              id: tkhdBox.tkhd.track_id,
              enabled: tkhdBox.tkhd.track_enabled,
              width: tkhdBox.tkhd.width,
              height: tkhdBox.tkhd.height,
              chunks: 0,
            };
            let mdiaBox = trakBox.trak.children.find(b => b.hdr.type === 'mdia');
            if (mdiaBox) {
              let mdhdBox = mdiaBox.mdia.children.find(b => b.hdr.type === 'mdhd');
              let hdlrBox = mdiaBox.mdia.children.find(b => b.hdr.type === 'hdlr');
              if (hdlrBox) {
                track.type = hdlrBox.hdlr.handler_type;
              }
              if (mdhdBox) {
                track.language = mdhdBox.mdhd.language;
                track.timescale = mdhdBox.mdhd.timescale;
              }
            }
            tracks.push(track);
          }
        });
      } else if (box.hdr.type === 'moof') {
        let mfhdBox = box.moof.children.find(b => b.hdr.type === 'mfhd');
        let chunk = {};
        if (mfhdBox) {
          chunk.seqNo = mfhdBox.mfhd.sequence_number;
        }
        let trafBoxes = box.moof.children.filter(b => b.hdr.type === 'traf');
        trafBoxes.forEach(trafBox => {
          let tfhdBox = trafBox.traf.children.find(b => b.hdr.type === 'tfhd');
          if (tfhdBox) {
            let track = tracks.find(t => t.id === tfhdBox.tfhd.track_id);
            if (track) {
              track.chunks += 1;

              let tfdtBox = trafBox.traf.children.find(b => b.hdr.type === 'tfdt');
              if (tfdtBox) {
                if (!chunk[track.type]) {
                  chunk[track.type] = {};
                }
                chunk[track.type].dts = tfdtBox.tfdt.base_media_decode_time;
                chunk[track.type].timescale = track.timescale;
              } 
            }
          }
        });
        chunks.push(chunk);
      }
    });
    this.setState({ tracks: tracks, chunks: chunks });
  }

  render() {
    let tracks = this.state.tracks;
    let chunks = this.state.chunks;
    return(
      <div>
        <table className="mp4info-table">
          <thead>
            <tr>
              <th>Track</th>
              <th>Enabled</th>
              <th>Type</th>
              <th>Language</th>
              <th>Display Size</th>
              <th>Chunks</th>
            </tr>
          </thead>
          <tbody>
            { tracks ? tracks.map((track, idx) => {
              return (
                <tr key={idx}>
                  <td>{track.id}</td>
                  <td>{track.enabled ? 'YES' : 'NO'}</td>
                  <td>{track.type}</td>
                  <td>{track.language}</td>
                  <td>{track.type === 'vide' ? track.width + 'x' + track.height : ''}</td>
                  <td>{track.chunks}</td>
                </tr>
              )
            }) : null}
          </tbody>
        </table>
        { chunks && chunks.length > 0 ?
          <table className="mp4info-table">
            <thead>
              <tr>
                <th>Sequence Numbers</th>
                <th>Decode Timestamps</th>
                <th>Timescale</th>
                <th>Timeline Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{chunks[0].seqNo} - {chunks[chunks.length - 1].seqNo}</td>
                <td>{chunks[0].vide ? chunks[0].vide.dts - chunks[chunks.length - 1].vide.dts : "n/a"}</td>
                <td>{chunks[0].vide ? chunks[0].vide.timescale : "n/a"}</td>
                <td>{chunks[0].vide ? ((chunks[chunks.length -1].vide.dts - chunks[0].vide.dts + 1) / chunks[0].vide.timescale)*1000 + "ms" : "n/a"}</td>
              </tr>
            </tbody>
          </table>
        : null }
      </div>
    )
  }
}

export default MP4Info;