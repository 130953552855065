import React, { Component } from 'react';
import './App.css';
import SegmentInspector from './SegmentInspector.js';

// Examples:
// https://ps-rbm-vod-origin.northeurope.cloudapp.azure.com/devgroup/enigmatv/assets/b4f80a5b-41a5-4d24-aad8-a55814c2f256_enigma/materials/b4f80a5b-41a5-4d24-aad8-a55814c2f256-1_enigma/vod.ism/vod-video=6800000-8.ts
// https://maitv-vod.lab.eyevinn.technology/stswe17-ozer.mp4/2000/2000-00002.ts
// https://hw-deliveryevaluation.tls1.yospace.com/u/file~video_MP2T~6400-h-1920x1080-6800-1-1.ts/1/m/0/x/3/o/tjfb/redbeedev01.ts
// https://testcontent.eyevinn.technology/fragments/video.dash

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>Quickly analyze a video segment online</p>
          <p className="small">[Supported formats: MPEG-TS (AVC/AAC) and MP4/ISOBMFF]</p>
          <SegmentInspector default="https://testcontent.eyevinn.technology/fragments/cmaf_chunk.mp4"/>
        </header>
        <footer className="App-footer">
          <p>Based on Javascript library <a href="https://github.com/Eyevinn/hls-ts-js">HLS-TS.js</a> | <a href="https://inspect.eyevinn.technology/docs/index.html">API Docs</a> and <a href="https://github.com/Eyevinn/fmp4-js">fMP4.js</a></p>
          <p className="small">Copyright 2018- Eyevinn Technology AB. All rights reserved</p>
        </footer>
      </div>
    );
  }
}

export default App;
