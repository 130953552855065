import React, { Component } from 'react';
import Plot from 'react-plotly.js';

const HlsTs = require('hls-ts');

class AVCDataPlot extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const avcParser = HlsTs.createAvcParser(this.props.payload);
    const nalUnits = avcParser.getNalUnits();

    let firstAvcPTS = HlsTs.programs.find(a => a.type === "avc").pts[0];

    let packets = {};
    for (let i = 0; i < nalUnits.length; i++) {
      let nalu = nalUnits[i];
      if (nalu.pes) {
        let pts = nalu.pes.pts;
        let offset = Math.floor(((pts - firstAvcPTS) / 90000));
        if (typeof packets[offset] === 'undefined') {
          packets[offset] = 0;
        }
        packets[offset] += (nalu.data.byteLength * 8);
      }
    }
    let trace1 = {
      x: Object.keys(packets),
      y: Object.keys(packets).map(k => packets[k]),
      type: 'bar',
      name: 'NAL Unit Sizes (bits)',
    };
    let avgBitrate = Object.keys(packets).map(k => packets[k]).reduce((p, c) => p + c, 0) / Object.keys(packets).length;
    let trace2 = {
      x: Object.keys(packets),
      y: Array(Object.keys(packets).length).fill(avgBitrate),
      type: 'scatter',
      mode: 'line',
      name: 'Average Bitrate (bps)'
    };
    this.setState({ data: [ trace1, trace2 ] });
  }

  render() {
    let layout = {
      title: 'Video Packages',
      barmode: 'stack',
      showlegend: true,
      legend: {
        "orientation": "h"
      },
      yaxis: {
        title: 'Bits'
      },
    }
    return (
      <div>
        {this.state.data ?
          <Plot data={this.state.data} layout={layout}></Plot>
        : null }
      </div>
    )
  }
}

export default AVCDataPlot;