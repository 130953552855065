import React, { Component } from 'react';

class ResponseDetails extends Component {

  render() {
    let headers = this.props.headers;
    let timings;
    if (this.props.timings) {
      timings = {};
      Object.keys(this.props.timings).forEach(k => {
        timings[k] = Math.floor(this.props.timings[k]);
      });
    }
    return (
      <div>
        <table className="metadata">
          <tbody>
            {headers ? Object.keys(headers).map((hdrkey, index) => {
              return (
                <tr key={index}>
                  <td><b>{hdrkey}</b></td><td>{headers[hdrkey]}</td>
                </tr>
              )
            }) : null}
          </tbody>
        </table>
        {timings ?
        <table className="metadata">
          <thead>
            <tr><th>DNS</th><th>Download</th><th>First Byte</th><th>TCP</th><th>Wait</th><th>Total</th></tr>
          </thead>
          <tbody>
            <tr>
              <td>{timings.dns}ms</td>
              <td>{timings.download}ms</td>
              <td>{timings.firstByte}ms</td>
              <td>{timings.tcp}ms</td>
              <td>{timings.wait}ms</td>
              <td>{timings.total}ms</td>
            </tr>
          </tbody>
        </table> : null }
      </div>
    )
  }
}

export default ResponseDetails;