import React, { Component } from 'react';

class ProgramMapTables extends Component {

  render() {
    return (
      <table className="metadata">
        <thead>
          <tr>
            <th>Packet Identifier</th>
            <th>Type</th>
            <th>Packets</th>
            <th>PTS</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {this.props.programs ? this.props.programs.map((pmt, index) => {
            return (
              <tr key={index}>
                <td>0x{pmt.id.toString(16)}</td>
                <td>{pmt.type}</td>
                <td>{pmt.packets}</td>
                <td>{pmt.pts[0]} - {pmt.pts[pmt.pts.length - 1]}</td>
                <td>{(pmt.pts[0] / 90000).toFixed(2)}s - {(pmt.pts[pmt.pts.length - 1] / 90000).toFixed(2)}s</td>
              </tr>
            )
          }) : null}
        </tbody>
      </table>
    )
  }
}

export default ProgramMapTables;