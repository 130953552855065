import React, { Component } from 'react';
import MP4Box from './MP4Box.js';

class MP4Boxes extends Component {
  render() {
    return(
    <div className="mp4boxes">
      { this.props.boxes ? this.props.boxes.map((box, index) => {
        return(
          <MP4Box key={index} box={box}/>
        )
      }) : null }
    </div>
    )
  }
}

export default MP4Boxes;