import React, { Component } from 'react';
import Plot from 'react-plotly.js';

const HlsTs = require('hls-ts');

class SegmentPlot extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const avcParser = HlsTs.createAvcParser(this.props.avcPayload);
    const nalUnits = avcParser.getNalUnits();

    let firstAvcPTS = HlsTs.programs.find(a => a.type === "avc").pts[0];
    let nalTypes = {};

    for (let i = 0; i < nalUnits.length; i++) {
      const nalu = nalUnits[i];
      if (nalu.pes) {
        let pts = nalu.pes.pts;
        let offset = ((pts - firstAvcPTS) / 90000) * 1000;
        if (!nalTypes[nalu.type]) {
          nalTypes[nalu.type] = [];
        }
        nalTypes[nalu.type].push(offset);
      }
    }
    let data = [];
    for (let i = 0; i < Object.keys(nalTypes).length; i++) {
      let key = Object.keys(nalTypes)[i];
      if (avcParser.nalUnitType(key)) {
        let trace = {
          x: nalTypes[key],
          y: Array(nalTypes[key].length).fill(1),
          type: 'bar',
          name: avcParser.nalUnitType(key),
        }
        data.push(trace);
      }
    }
    if (this.props.aacPayload) {
      const aacParser = HlsTs.createAacParser(this.props.aacPayload);
      const aacAdtsFrames = aacParser.getAdtsFrames();
      let firstFrame = aacAdtsFrames[0];
      if (firstFrame) {
        let aacPackets = {};

        for (let i = 0; i < aacAdtsFrames.length; i++) {
          let frame = aacAdtsFrames[i];
          if (frame.pes) {
            let pts = frame.pes.pts;
            let offset = ((pts - firstAvcPTS) / 90000) * 1000;
            if (!aacPackets[offset]) {
              aacPackets[offset] = [];
            }
            aacPackets[offset].push(frame);
          }
        }
        //let avgFrameCount = Object.keys(aacPackets).map(k => aacPackets[k].length).reduce((p, c) => p + c, 0) / Object.keys(aacPackets).length;
        let trace = {
          x: Object.keys(aacPackets),
          y: Array(Object.keys(aacPackets).length).fill(1),
          type: 'bar',
          name: `ADTS Frames (MPEG${firstFrame.mpegVersion} ${aacParser.audioType(firstFrame.audioObjectType)} ${firstFrame.channels}ch ${firstFrame.samplingRate}Hz)`,
        }
        data.push(trace);
      }
    }
    this.setState({ data: data });
  }

  render() {
    let layout = {
      title: 'Audio & Video',
      barmode: 'stack',
      showlegend: true,
      legend: {
        "orientation": "h"
      },
    }
    return (
      <div>
        {this.state.data ?
          <Plot data={this.state.data} layout={layout}></Plot>
        : null }
      </div>
    );
  }
}

export default SegmentPlot;